import { Container, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const Privacy = () => {
    const { t } = useTranslation('privacyPage'); // Use the "privacyPage" namespace

    return (
        <Container maxWidth='650px'>
            <Heading as='h1'>{t('heading')}</Heading>

            <Heading as='h2'>{t('cookies')}</Heading>
            <Text>{t('essentialCookies')}</Text>
            <Text>{t('thirdPartyCookies')}</Text>

            <Heading as='h2'>{t('pii')}</Heading>
            <Text>{t('securePii')}</Text>
            <Text>{t('minimumPii')}</Text>
            <ul>
                <li>{t('emailAddress')}</li>
                <li>{t('preferredDisplayName')}</li>
                <li>{t('createdAt')}</li>
                <li>{t('lastLogin')}</li>
            </ul>
            <Text>{t('emailDisplay')}</Text>
            <Text>
                {t('cosaUsesAuth0')}
                <a href='https://auth0.com/docs/secure/data-privacy-and-compliance/gdpr'>
                    {t('auth0GdprLinkText')} 🔗
                </a>
                .
            </Text>
            <Text>{t('auth0LocationStorage')}</Text>
            <Text>
                {t('toRequestPiiRemoval', {
                    email: window.ENVIRONMENT?.REACT_APP_SUPPORT_EMAIL,
                })}
            </Text>

            <Heading as='h2'>{t('Email')}</Heading>
            <Text>{t('sisEmail')}</Text>

            <Heading as='h2'>{t('contactHeading')}</Heading>
            <Text>
                {t('subjectAccessErasureContact', {
                    email: window.ENVIRONMENT?.REACT_APP_SUPPORT_EMAIL,
                })}
            </Text>

            <Text>
                {t('otherContact', {
                    email: window.ENVIRONMENT?.REACT_APP_SUPPORT_EMAIL,
                })}
            </Text>
        </Container>
    );
};

export default Privacy;
