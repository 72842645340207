import { Container, Alert, AlertIcon, AlertTitle } from '@chakra-ui/react';

export default function ErrorAlert({ error }: { error: string }) {
    return (
        <Container maxWidth='650px' padding='20px'>
            <Alert status='error' borderRadius='5px'>
                <AlertIcon />
                <AlertTitle>{error}</AlertTitle>
            </Alert>
        </Container>
    );
}
