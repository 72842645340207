import { Text, MenuButton, Button, Flex } from '@chakra-ui/react';
import { lightGrayLine } from '../util/styles';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

const OrganizationMenuButton = ({
    isOpen,
    value,
}: {
    isOpen: boolean;
    value: string;
}) => {
    return (
        <MenuButton
            display='flex'
            alignItems='center'
            justifyContent='flex-start'
            padding='8px 16px 8px 12px'
            width='100%'
            border={lightGrayLine}
            isActive={isOpen}
            as={Button}
            borderRadius='2px'
            rightIcon={
                isOpen ? (
                    <BsChevronUp size='12px' />
                ) : (
                    <BsChevronDown size='12px' />
                )
            }
        >
            <Flex width='100%' alignItems='flex-start'>
                <Text variant='medium'>{value}</Text>
            </Flex>
        </MenuButton>
    );
};

export default OrganizationMenuButton;
