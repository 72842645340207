import { VStack, Spinner } from '@chakra-ui/react';
import { selectOrganization } from '../../reducers/organization';
import PageHeading from '../PageHeading';
import { useAppSelector } from '../../util/hooks';
import { useTranslation } from 'react-i18next';

const Users = () => {
    const { t } = useTranslation('organizationPage');
    const organization = useAppSelector(selectOrganization);

    if (!organization) {
        return <Spinner />;
    }

    return (
        <VStack
            width='100%'
            height='100%'
            alignItems='center'
            justifyContent='flex-start'
        >
            <PageHeading title={t('users')} />
        </VStack>
    );
};

export default Users;
