export default function env(v: string): string | undefined {
    if (window.ENVIRONMENT && v in window.ENVIRONMENT) {
        return window.ENVIRONMENT[v];
    }

    return process.env[v];
}

export const fetchTimeoutMs: number = parseInt(
    env('REACT_APP_FETCH_TIMEOUT_MS') || '1500',
    10
);
