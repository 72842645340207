import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Stack,
    VStack,
    Text,
    Radio,
    RadioGroup,
    Menu,
    MenuList,
    MenuItem,
    FormControl,
    Input,
    FormLabel,
    Select,
    HStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../util/hooks';
import { selectIsStaff } from '../reducers/auth';

import StaffOnlyCard from './StaffOnlyCard';
import {
    NewOrganizationParamsType,
    organizationRelationshipType,
    organizationTypeType,
} from '../types/reducers/organizations';
import {
    clientStatusOptions,
    organizationTypeOptions,
} from '../util/constants';
import OrganizationMenuButton from './OrganizationMenuButton';
import theme from '../theme';
import { COUNTRY_CHOICES } from '../util/countries';
import { inputStyles, selectStyle, spacing4 } from '../util/styles';
import { isEmpty } from 'lodash';

interface ModalProps {
    isOpen: boolean;
    title: string;
    onCloseCallback: () => void;
    onSubmitCallback: (prop: NewOrganizationParamsType) => void;
    finalRef?: React.RefObject<HTMLElement>;
    relatedOrgType?: organizationRelationshipType;
}

const AddOrganizationModal = ({
    isOpen,
    title,
    onCloseCallback,
    onSubmitCallback,
    finalRef,
    relatedOrgType,
}: ModalProps) => {
    const { t } = useTranslation('addOrganizationModal');
    const { t: tc } = useTranslation('common');
    const { midGray } = theme.colors.text;
    const is_staff = useAppSelector(selectIsStaff);

    const initialFormValues: NewOrganizationParamsType = {
        client_status: 'ACTIVE_CLIENT',
        name: '',
        country: null,
        address: null,
        latitude: null,
        longitude: null,
        organization_type: 'CUSTOMER_SUPPLIER',
        user: {
            email: '',
            display_name: '',
            preferred_language: 'en',
        },
        ...(relatedOrgType && { relationship: relatedOrgType }),
    };

    const [newOrgFormValues, setNewOrgFormValues] =
        useState<NewOrganizationParamsType>(initialFormValues);

    const handleInputChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name: inputType, value } = event.target;
        if (inputType in newOrgFormValues.user) {
            setNewOrgFormValues({
                ...newOrgFormValues,
                user: {
                    ...newOrgFormValues.user,
                    [inputType]: value,
                },
            });
        } else {
            setNewOrgFormValues({
                ...newOrgFormValues,
                [inputType]: value,
            });
        }
    };

    const handleStaffInputChange = (inputType: string, value: string) => {
        setNewOrgFormValues({
            ...newOrgFormValues,
            [inputType]: value,
        });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmitCallback(newOrgFormValues);
    };

    const handleClose = () => {
        setNewOrgFormValues(initialFormValues);
        onCloseCallback();
    };

    const renderRequiredText = (text: string) => (
        <HStack gap='3px'>
            <Text variant='small'>{text}</Text>
            <Text variant='small' color='red'>
                *
            </Text>
        </HStack>
    );

    const nameIsInValid: boolean = isEmpty(newOrgFormValues.name);
    const countryIsInvalid: boolean = isEmpty(newOrgFormValues.country);
    const adminNameIsInvalid: boolean = isEmpty(
        newOrgFormValues.user.display_name
    );
    const adminEmailIsInvalid: boolean = isEmpty(newOrgFormValues.user.email);
    const adminLanguageIsInvalid: boolean = isEmpty(
        newOrgFormValues.user.preferred_language as string
    );

    const isFormInvalid =
        nameIsInValid ||
        countryIsInvalid ||
        adminEmailIsInvalid ||
        adminNameIsInvalid ||
        adminLanguageIsInvalid;

    return (
        <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t(title)}</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit}>
                    <ModalBody
                        display='flex'
                        flexFlow='column'
                        gap='24px'
                        pt='0px'
                    >
                        <VStack spacing={4} alignItems='start' pb={2} pt={2}>
                            <Text variant='md'>{t('genInfoFormTitle')}</Text>
                            <FormControl>
                                <FormLabel>
                                    {renderRequiredText(t('orgNameFormLabel'))}
                                </FormLabel>
                                <Input
                                    {...inputStyles}
                                    name='name'
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>
                                    {renderRequiredText(
                                        t('orgCountryFormLabel')
                                    )}
                                </FormLabel>
                                <Select
                                    {...selectStyle}
                                    name='country'
                                    placeholder={t('orgCountryDefault')}
                                    onChange={handleInputChange}
                                >
                                    {Object.entries(COUNTRY_CHOICES).map(
                                        ([code, label]) => (
                                            <option key={code} value={code}>
                                                {label}
                                            </option>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </VStack>
                        {is_staff && (
                            <StaffOnlyCard
                                title={tc('staffCardTitle')}
                                subheading={tc('staffCardSubheading')}
                                isModal
                            >
                                <VStack spacing={4} width='100%'>
                                    <VStack
                                        width='100%'
                                        alignItems='flex-start'
                                    >
                                        {renderRequiredText(tc('orgType'))}
                                        <RadioGroup
                                            onChange={(
                                                value: organizationTypeType
                                            ) =>
                                                handleStaffInputChange(
                                                    'organization_type',
                                                    value
                                                )
                                            }
                                            value={
                                                newOrgFormValues.organization_type
                                            }
                                        >
                                            <Stack direction='column'>
                                                {organizationTypeOptions.map(
                                                    optionType => (
                                                        <Radio
                                                            colorScheme='gray'
                                                            value={optionType}
                                                        >
                                                            {tc(optionType)}
                                                        </Radio>
                                                    )
                                                )}
                                            </Stack>
                                        </RadioGroup>
                                    </VStack>
                                    <VStack
                                        width='100%'
                                        alignItems='flex-start'
                                    >
                                        {renderRequiredText(tc('dashboard'))}
                                        <Menu>
                                            {({ isOpen }) => (
                                                <>
                                                    <OrganizationMenuButton
                                                        isOpen={isOpen}
                                                        value={tc(
                                                            newOrgFormValues.client_status
                                                        )}
                                                    />
                                                    <MenuList width='100%'>
                                                        {clientStatusOptions.map(
                                                            statusType => (
                                                                <MenuItem
                                                                    onClick={() =>
                                                                        handleStaffInputChange(
                                                                            'client_status',
                                                                            statusType
                                                                        )
                                                                    }
                                                                >
                                                                    <Text variant='small'>
                                                                        {tc(
                                                                            statusType
                                                                        )}
                                                                    </Text>
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </MenuList>
                                                </>
                                            )}
                                        </Menu>
                                    </VStack>
                                </VStack>
                            </StaffOnlyCard>
                        )}
                        <VStack spacing={2} pb={2} pt={2} gap={spacing4}>
                            <VStack spacing={2} alignItems='start'>
                                <Text variant='md'>
                                    {t('orgAdminFormTitle')}
                                </Text>
                                <Text variant='small' color={midGray}>
                                    {t('orgAdminFormSubTitle')}
                                </Text>
                            </VStack>
                            <FormControl>
                                <FormLabel variant='small'>
                                    {renderRequiredText(
                                        t('adminUsernameFormLabel')
                                    )}
                                </FormLabel>
                                <Input
                                    {...inputStyles}
                                    name='display_name'
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel variant='small'>
                                    {renderRequiredText(
                                        t('adminEmailFormLabel')
                                    )}
                                </FormLabel>
                                <Input
                                    {...inputStyles}
                                    type='email'
                                    name='email'
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl isInvalid={adminLanguageIsInvalid}>
                                <FormLabel>
                                    {renderRequiredText(
                                        t('adminLangFormLabel')
                                    )}
                                </FormLabel>
                                <Select
                                    {...selectStyle}
                                    name='preferred_language'
                                    onChange={handleInputChange}
                                >
                                    <option value='en'>{tc('en')}</option>
                                    <option value='es'>{tc('es')}</option>
                                </Select>
                            </FormControl>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='primary' mr={3} onClick={handleClose}>
                            {t('discard')}
                        </Button>
                        <Button
                            variant='submit'
                            mr={3}
                            type='submit'
                            isDisabled={isFormInvalid}
                        >
                            {t('createOrg')}
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default AddOrganizationModal;
