import { Flex } from '@chakra-ui/react';
import { pagePadding } from '../util/styles';

const UsersPage = () => {
    return (
        <Flex
            width='100%'
            height='100%'
            alignItems='center'
            justifyContent='center'
            padding={pagePadding}
        >
            Users Page
        </Flex>
    );
};

export default UsersPage;
