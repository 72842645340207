import React from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerOverlay,
    Text,
    Button,
    VStack,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    HStack,
} from '@chakra-ui/react';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../util/hooks';
import { selectIsStaff } from '../reducers/auth';
import { useTranslation } from 'react-i18next';

import { RelatedOrganizationType } from '../types/reducers/organizations';
import { darkGray, spacing2, spacing6, spacing8 } from '../util/styles';
import StaffOnlyCard from './StaffOnlyCard';
import { setNextLocation } from '../reducers/nextLocation';

interface ModalProps {
    isOpen: boolean;
    relatedOrg: RelatedOrganizationType;
    onCloseCallback: () => void;
    finalRef?: React.RefObject<HTMLElement>;
}

const RelatedOrgSnapshotDrawer = ({
    isOpen,
    relatedOrg,
    onCloseCallback,
    finalRef,
}: ModalProps) => {
    const { t } = useTranslation('relatedOrganizationDrawer');
    const is_staff = useAppSelector(selectIsStaff);
    const dispatch = useAppDispatch();

    const handleStaffButtonClick = () =>
        dispatch(
            setNextLocation({
                origin: `/organizations/${relatedOrg.related_org_id}/`,
            })
        );

    return (
        <Drawer
            placement='right'
            size='md'
            variant='profile'
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onCloseCallback}
        >
            <DrawerOverlay />
            <DrawerContent padding={spacing6}>
                <DrawerCloseButton top={spacing6} right={spacing6} />
                <DrawerHeader padding='0px'>
                    <VStack spacing={1} alignItems='flex-start'>
                        <Text variant='headingMd'>
                            {relatedOrg.related_org_name}
                        </Text>
                        <Text variant='headingSmall'>
                            {t(relatedOrg.related_org_organization_type)}
                        </Text>
                    </VStack>
                </DrawerHeader>
                <DrawerBody padding='24px 0px 0px 0px' height='100%'>
                    <VStack height='100%' justifyContent='space-between'>
                        <VStack gap={spacing6} width='100%'>
                            <HStack
                                justifyContent='space-between'
                                width='100%'
                                paddingRight={spacing8}
                            >
                                <VStack
                                    spacing={1}
                                    width='fit-content'
                                    alignItems='flex-start'
                                >
                                    <Text variant='small'>{t('addedBy')}</Text>
                                    <Text variant='small' color='#0C0C0C'>
                                        {relatedOrg.related_org_created_by}
                                    </Text>
                                </VStack>
                                <VStack
                                    spacing={1}
                                    width='fit-content'
                                    alignItems='flex-start'
                                >
                                    <Text variant='small'>
                                        {t('createdAt')}
                                    </Text>
                                    <Text variant='small' color='#0C0C0C'>
                                        {moment(
                                            relatedOrg.related_org_created_at
                                        ).format('MMM D YYYY')}
                                    </Text>
                                </VStack>
                                <VStack
                                    spacing={1}
                                    width='fit-content'
                                    alignItems='flex-start'
                                >
                                    <Text variant='small'>{t('status')}</Text>
                                    <Text variant='small' color='#0C0C0C'>
                                        {t(relatedOrg.status)}
                                    </Text>
                                </VStack>
                            </HStack>
                            <VStack
                                spacing={4}
                                alignItems='flex-start'
                                width='100%'
                            >
                                <Text variant='md'>{t('People')}</Text>
                                <Table variant='simple'>
                                    <Thead>
                                        <Tr>
                                            <Th
                                                textTransform='none'
                                                paddingX={spacing2}
                                            >
                                                <Text variant='medium'>
                                                    {t('Name')}
                                                </Text>
                                            </Th>
                                            <Th
                                                textTransform='none'
                                                paddingX={spacing2}
                                            >
                                                <Text
                                                    variant='medium'
                                                    color={darkGray}
                                                >
                                                    {t('Role')}
                                                </Text>
                                            </Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {relatedOrg.members.map(person => (
                                            <Tr
                                                key={
                                                    person.user_display_name ??
                                                    person.user_email
                                                }
                                            >
                                                <Td paddingX={spacing2}>
                                                    {person.user_display_name ??
                                                        person.user_email}
                                                </Td>
                                                <Td paddingX={spacing2}>
                                                    <Text
                                                        variant='small'
                                                        fontSize='16px'
                                                    >
                                                        {t(person.role)}
                                                    </Text>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </VStack>
                        </VStack>
                        {is_staff && (
                            <StaffOnlyCard
                                title=''
                                subheading='Only COSA users can see this section'
                            >
                                <Button
                                    width='100%'
                                    onClick={handleStaffButtonClick}
                                >
                                    {t('staffButtonAction')}
                                </Button>
                            </StaffOnlyCard>
                        )}
                    </VStack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default RelatedOrgSnapshotDrawer;
