import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface appState {
    language: 'en' | 'es';
    isProfileOpen: boolean;
}

const initialState: appState = {
    language: 'en',
    isProfileOpen: false,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setLanguage: (state: appState, { payload }) => {
            state.language = payload;
        },
        setIsProfileOpen: (state: appState, { payload }) => {
            state.isProfileOpen = payload;
        },
        toggleLanguage: (state: appState) => {
            state.language = state.language === 'en' ? 'es' : 'en';
        },
    },
});

export const { setLanguage, toggleLanguage, setIsProfileOpen } =
    appSlice.actions;

export const selectLanguage = (state: RootState) => state.app.language;
export const selectIsProfileOpen = (state: RootState) =>
    state.app.isProfileOpen;
export default appSlice.reducer;
