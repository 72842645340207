import {
    NewOrganizationParamsType,
    OrganizationResponseType,
    OrganizationType,
    OrganizationUpdateType,
} from './types/reducers/organizations';
import { LoginType, UserType, UserUpdateType } from './types/reducers/auth';
import axios from 'axios';
import { URLs } from './util/constants';
import { fetchTimeoutMs } from './env';

axios.defaults.baseURL = '/api';
// Used to make authenticated HTTP requests to Django
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';
export const API = axios.create({
    headers: {
        credentials: 'same-origin',
    },
});

export const getOrganizations = async (): Promise<OrganizationType[]> => {
    return new Promise((resolve, reject) => {
        const url = URLs.makeOrganizationsURL();
        API.get(url, { timeout: fetchTimeoutMs })
            .then(response => response.data)
            .then(resolve)
            .catch(error => reject(`Error retrieving ${url}: ${error}`));
    });
};

export const getOrganization = async (
    id: string
): Promise<OrganizationResponseType> => {
    return new Promise((resolve, reject) => {
        const url = URLs.makeOrganizationURL(id);
        API.get(url, { timeout: fetchTimeoutMs })
            .then(response => response.data)
            .then(resolve)
            .catch(error => reject(`Error retrieving ${url}: ${error}`));
    });
};

export const getUserOrganization =
    async (): Promise<OrganizationResponseType> => {
        return new Promise((resolve, reject) => {
            const url = URLs.makeUserOrganizationURL();
            API.get(url, { timeout: fetchTimeoutMs })
                .then(response => response.data)
                .then(resolve)
                .catch(error => reject(`Error retrieving ${url}: ${error}`));
        });
    };

export async function getLoginWithSessionId(): Promise<LoginType> {
    return API.get(URLs.makeLoginURL()).then(response => response.data);
}

export const postNewOrganization = async (
    params: NewOrganizationParamsType
): Promise<OrganizationType> => {
    return new Promise((resolve, reject) => {
        const url = URLs.makeNewOrganizationURL();
        API.post(url, { ...params, timeout: fetchTimeoutMs })
            .then(response => response.data)
            .then(resolve)
            .catch(error => reject(`Error sending ${url}: ${error}`));
    });
};

export const disableOrganization = async (
    id: string
): Promise<OrganizationType> => {
    return new Promise((resolve, reject) => {
        const url = URLs.makeDisableOrganizationURL(id);
        API.put(url, { timeout: fetchTimeoutMs })
            .then(response => response.data)
            .then(resolve)
            .catch(error => reject(`Error disabling organization: ${error}`));
    });
};

export const postNewRelatedOrganization = async (
    params: NewOrganizationParamsType,
    id: string
): Promise<OrganizationType> => {
    return new Promise((resolve, reject) => {
        const url = URLs.makeNewRelatedOrganizationURL(id);
        API.post(url, { ...params, timeout: fetchTimeoutMs })
            .then(response => response.data)
            .then(resolve)
            .catch(error => reject(`Error sending ${url}: ${error}`));
    });
};

export async function updateUser(
    id: string,
    data: UserUpdateType
): Promise<UserType> {
    return API.put(URLs.makeUpdateUserURL(id), data).then(
        response => response.data
    );
}

export async function putOrganization(
    id: string,
    data: OrganizationUpdateType
): Promise<OrganizationType> {
    return API.put(URLs.makeUpdateOrganizationURL(id), data).then(
        response => response.data
    );
}
