import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { Box } from '@chakra-ui/react';

import { loginWithSessionId } from '../reducers/auth';
import { useAppDispatch } from '../util/hooks';
import DelayedSpinner from './DelayedSpinner';

const SiteWrapper = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(loginWithSessionId());
    }, []); // eslint-disable-line

    return (
        <Box w='100%' h='100vh' data-testid='site-wrapper'>
            <Suspense fallback={<DelayedSpinner />}>
                <Outlet />
            </Suspense>
        </Box>
    );
};

export default SiteWrapper;
