import { Text, VStack } from '@chakra-ui/react';

import theme from '../theme';
import { ReactFragment } from 'react';

const { orange } = theme.colors.text;

interface StaffOnlyCardProps {
    title: string;
    subheading?: string;
    isModal?: boolean;
    children?: ReactFragment | JSX.Element;
}

const StaffOnlyCard = ({
    title,
    subheading,
    isModal,
    children,
}: StaffOnlyCardProps) => {
    return (
        <VStack
            display='flex'
            alignItems='flex-start'
            borderTop={`4px solid ${orange}`}
            width='100%'
            backgroundColor={'rgba(241, 136, 4, 0.05)'}
            padding={isModal ? '16px' : '24px'}
            gap={isModal ? '16px' : '24px'}
            borderRadius='4px'
        >
            <VStack
                display='flex'
                alignItems='flex-start'
                justifyContent='flex-start'
                gap='2px'
            >
                <Text variant='headingMd'>{title}</Text>
                <Text
                    color={orange}
                    fontSize='14px'
                    fontStyle='normal'
                    fontWeight='400'
                    lineHeight='150%'
                >
                    {subheading}
                </Text>
            </VStack>
            {children}
        </VStack>
    );
};

export default StaffOnlyCard;
