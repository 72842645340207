import React, { useEffect, useReducer } from 'react';
import {
    VStack,
    HStack,
    Text,
    Spinner,
    Select,
    Input,
    Button,
    useToast,
} from '@chakra-ui/react';
import {
    selectOrganization,
    setOrganization,
    updateOrganization,
} from '../../reducers/organization';
import PageHeading from '../PageHeading';
import StaffOnlyCard from '../StaffOnlyCard';
import { useAppDispatch, useAppSelector } from '../../util/hooks';
import { selectIsStaff } from '../../reducers/auth';
import {
    inputStyles,
    lightGray,
    selectStyle,
    spacing12,
    spacing6,
} from '../../util/styles';
import { useTranslation } from 'react-i18next';
import {
    clientStatusType,
    OrganizationType,
    organizationTypeType,
} from '../../types/reducers/organizations';
import {
    ACTIVE_CLIENT,
    AUDITOR,
    CUSTOMER_SUPPLIER,
    INACTIVE_CLIENT,
    NOT_A_CLIENT,
} from '../../util/constants';
import { COUNTRY_CHOICES } from '../../util/countries';
import { disableOrganization } from '../../api';
import { fetchOrganizations } from '../../reducers/organizations';

type OrganizationState = {
    organization_type?: organizationTypeType;
    client_status?: clientStatusType;
    name?: string;
    address?: string;
    country?: string;
    latitude?: string;
    longitude?: string;
};

type Action =
    | { type: 'SET_ORG_TYPE'; payload: organizationTypeType }
    | { type: 'SET_CLIENT_STATUS'; payload: clientStatusType }
    | { type: 'SET_ORG_NAME'; payload: string }
    | { type: 'SET_ADDRESS'; payload: string | undefined }
    | { type: 'SET_COUNTRY'; payload: string | undefined }
    | { type: 'SET_LATITUDE'; payload: string | undefined }
    | { type: 'SET_LONGITUDE'; payload: string | undefined };

const initialState: OrganizationState = {};

const reducer = (
    state: OrganizationState,
    action: Action
): OrganizationState => {
    switch (action.type) {
        case 'SET_ORG_TYPE':
            return { ...state, organization_type: action.payload };
        case 'SET_CLIENT_STATUS':
            return { ...state, client_status: action.payload };
        case 'SET_ORG_NAME':
            return { ...state, name: action.payload };
        case 'SET_ADDRESS':
            return { ...state, address: action.payload };
        case 'SET_COUNTRY':
            return { ...state, country: action.payload };
        case 'SET_LATITUDE':
            return { ...state, latitude: action.payload };
        case 'SET_LONGITUDE':
            return { ...state, longitude: action.payload };
        default:
            return state;
    }
};

const Profile = () => {
    const { t } = useTranslation('organizationPage');
    const { t: tc } = useTranslation('common');
    const dispatchRedux = useAppDispatch();
    const toast = useToast();
    const is_staff = useAppSelector(selectIsStaff);
    const organization = useAppSelector(selectOrganization);

    const [state, dispatch] = useReducer(reducer, initialState);

    const resetForm = (organization: OrganizationType | null) => {
        if (organization) {
            dispatch({
                type: 'SET_ORG_TYPE',
                payload: organization.organization_type,
            });
            dispatch({
                type: 'SET_CLIENT_STATUS',
                payload: organization.client_status,
            });
            dispatch({ type: 'SET_ORG_NAME', payload: organization.name });
            dispatch({ type: 'SET_ADDRESS', payload: organization.address });
            dispatch({ type: 'SET_COUNTRY', payload: organization.country });
            dispatch({ type: 'SET_LATITUDE', payload: organization.latitude });
            dispatch({
                type: 'SET_LONGITUDE',
                payload: organization.longitude,
            });
        }
    };

    const disableClickHandler = async () => {
        if (organization) {
            try {
                const data = await disableOrganization(organization?.id);
                dispatchRedux(setOrganization(data));
                toast({
                    position: 'top',
                    title: t('success'),
                    description: t('disableSuccessMessage'),
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            } catch (error) {
                toast({
                    position: 'top',
                    title: t('failure'),
                    description: t('disableFailureMessage'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    useEffect(() => {
        resetForm(organization);
    }, [organization]);

    if (!organization) {
        return <Spinner />;
    }

    const submitClickHandler = () => {
        const data = { ...state, ...{ reviewed: true } };

        const failureCallback = () => {
            toast({
                position: 'top',
                title: t('failure'),
                description: t('updateFailureMessage'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        };

        const successCallback = () => {
            toast({
                position: 'top',
                title: t('success'),
                description: t('updateSuccessMessage'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            if (is_staff) {
                dispatchRedux(fetchOrganizations());
            }
        };

        dispatchRedux(
            updateOrganization(
                organization.id,
                data,
                failureCallback,
                successCallback
            )
        );
    };

    return (
        <VStack
            width='100%'
            height='100%'
            alignItems='center'
            justifyContent='flex-start'
            gap={spacing12}
        >
            {/* TITLE */}
            <PageHeading title={t('homeTitle')} />
            {/* STAFF-ONLY SETTINGS */}
            {is_staff && (
                <StaffOnlyCard
                    title={t('staffCardTitle')}
                    subheading={t('staffCardSubheading')}
                >
                    <HStack gap={spacing6} width='100%'>
                        <VStack
                            width='100%'
                            display='flex'
                            alignItems='flex-start'
                        >
                            <Text variant='small'>{t('orgType')}</Text>
                            <Select
                                style={selectStyle}
                                value={state.organization_type}
                                onChange={e =>
                                    dispatch({
                                        type: 'SET_ORG_TYPE',
                                        payload: e.target
                                            .value as organizationTypeType,
                                    })
                                }
                            >
                                <option value={CUSTOMER_SUPPLIER}>
                                    {tc(CUSTOMER_SUPPLIER)}
                                </option>
                                <option value={AUDITOR}>{tc(AUDITOR)}</option>
                            </Select>
                        </VStack>
                        <VStack
                            width='100%'
                            display='flex'
                            alignItems='flex-start'
                        >
                            <Text variant='small'>{t('clientStatus')}</Text>
                            <Select
                                style={selectStyle}
                                value={state.client_status}
                                onChange={e =>
                                    dispatch({
                                        type: 'SET_CLIENT_STATUS',
                                        payload: e.target
                                            .value as clientStatusType,
                                    })
                                }
                            >
                                <option value={ACTIVE_CLIENT}>
                                    {tc(ACTIVE_CLIENT)}
                                </option>
                                <option value={INACTIVE_CLIENT}>
                                    {tc(INACTIVE_CLIENT)}
                                </option>
                                <option value={NOT_A_CLIENT}>
                                    {tc(NOT_A_CLIENT)}
                                </option>
                            </Select>
                        </VStack>
                    </HStack>
                </StaffOnlyCard>
            )}
            {/* GENERAL SETTINGS (white box) */}
            <VStack
                width='100%'
                backgroundColor='white'
                padding={spacing6}
                gap={spacing12}
            >
                <VStack width='100%' alignItems='flex-start' gap={spacing6}>
                    <Text variant='headingMd'>{t('generalInformation')}</Text>
                    <VStack
                        width='50%'
                        display='flex'
                        alignItems='flex-start'
                        paddingRight='12px'
                    >
                        <Text variant='small'>{t('organizationName')}</Text>
                        <Input
                            value={state.name}
                            {...inputStyles}
                            onChange={e =>
                                dispatch({
                                    type: 'SET_ORG_NAME',
                                    payload: e.target.value,
                                })
                            }
                        />
                    </VStack>
                </VStack>
                <VStack width='100%' alignItems='flex-start' gap={spacing6}>
                    <Text variant='headingMd'>{t('address')}</Text>
                    <HStack width='100%' gap={spacing6}>
                        <VStack
                            width='50%'
                            display='flex'
                            alignItems='flex-start'
                        >
                            <Text variant='small'>{t('address')}</Text>
                            <Input
                                {...inputStyles}
                                value={state.address}
                                onChange={e =>
                                    dispatch({
                                        type: 'SET_ADDRESS',
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </VStack>
                        <VStack
                            width='50%'
                            display='flex'
                            alignItems='flex-start'
                        >
                            <Text variant='small'>{t('country')}</Text>
                            <Select
                                value={state.country}
                                {...selectStyle}
                                onChange={e =>
                                    dispatch({
                                        type: 'SET_COUNTRY',
                                        payload: e.target.value,
                                    })
                                }
                            >
                                {Object.entries(COUNTRY_CHOICES).map(
                                    ([code, label]) => (
                                        <option key={code} value={code}>
                                            {label}
                                        </option>
                                    )
                                )}
                            </Select>
                        </VStack>
                    </HStack>
                    <HStack width='100%' gap={spacing6}>
                        <VStack
                            width='50%'
                            display='flex'
                            alignItems='flex-start'
                        >
                            <Text variant='small'>{t('latitude')}</Text>
                            <Input
                                value={state.latitude}
                                {...inputStyles}
                                onChange={e =>
                                    dispatch({
                                        type: 'SET_LATITUDE',
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </VStack>
                        <VStack
                            width='50%'
                            display='flex'
                            alignItems='flex-start'
                        >
                            <Text variant='small'>{t('longitude')}</Text>
                            <Input
                                value={state.longitude}
                                {...inputStyles}
                                onChange={e =>
                                    dispatch({
                                        type: 'SET_LONGITUDE',
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </VStack>
                    </HStack>
                </VStack>
            </VStack>
            {/* ACTION BUTTONS */}
            <HStack
                width='100%'
                justifyContent={is_staff ? 'space-between' : 'flex-end'}
                height='40px'
                alignItems='center'
            >
                {is_staff && (
                    <Button
                        height='100%'
                        variant='outline'
                        onClick={disableClickHandler}
                        isDisabled={
                            !organization ||
                            organization.client_status === INACTIVE_CLIENT
                        }
                    >
                        <Text>{t('disableOrg')}</Text>
                    </Button>
                )}

                <HStack gap='12px' height='100%'>
                    <Button
                        variant='ghost'
                        border='1px solid'
                        borderColor={lightGray}
                        _hover={{
                            background: lightGray,
                            border: '1px solid',
                            borderColor: 'black',
                        }}
                        onClick={() => resetForm(organization)}
                    >
                        <Text>{t('discardChanges')}</Text>
                    </Button>
                    <Button
                        variant='submit'
                        display='flex'
                        alignContent='center'
                        justifyContent='center'
                        onClick={submitClickHandler}
                    >
                        <Text>{t('saveChanges')}</Text>
                    </Button>
                </HStack>
            </HStack>
        </VStack>
    );
};

export default Profile;
