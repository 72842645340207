import { useTranslation } from 'react-i18next';

import { Text } from '@chakra-ui/react';

import theme from '../theme';
import { clientStatusType } from '../types/reducers/organizations';

const { green, lightGray } = theme.colors.brand;

const ClientStatusBadge = ({
    clientStatus,
}: {
    clientStatus: clientStatusType;
}) => {
    const { t } = useTranslation('organizationsPage');

    const key =
        clientStatus === 'ACTIVE_CLIENT'
            ? 'active'
            : clientStatus === 'INACTIVE_CLIENT'
            ? 'inactive'
            : 'nonClient';

    const text = t(key);
    const backgroundColor =
        clientStatus === 'ACTIVE_CLIENT' ? green : lightGray;
    const textColor = clientStatus === 'ACTIVE_CLIENT' ? 'white' : '#1A202C';

    return (
        <Text
            style={{
                color: textColor,
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '16px',
                letterSpacing: '0.36px',
                textTransform: 'uppercase',
                width: 'fit-content',
                padding: '2px',
            }}
            background={backgroundColor}
            borderRadius='2px'
        >
            {text}
        </Text>
    );
};

export default ClientStatusBadge;
