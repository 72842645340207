import { useState, useMemo, useRef } from 'react';
import {
    VStack,
    Spinner,
    Box,
    Text,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    HStack,
    Button,
    useToast,
    Flex,
} from '@chakra-ui/react';
import {
    addRelatedOrganization,
    selectIsAdmin,
    selectOrganization,
    selectOrganizationIsLoading,
} from '../../reducers/organization';
import { useAppDispatch, useAppSelector } from '../../util/hooks';
import { useTranslation } from 'react-i18next';
import {
    NewOrganizationParamsType,
    organizationRelationshipType,
    RelatedOrganizationType,
} from '../../types/reducers/organizations';
import { baseContainerStyle, lightGrayLine } from '../../util/styles';
import theme from '../../theme';
import AddOrganizationModal from '../AddOrganizationModal';
import { CUSTOMER, SUPPLIER } from '../../util/constants';
import { selectIsStaff } from '../../reducers/auth';
import RelatedOrgSnapshotDrawer from '../RelatedOrgSnapshotDrawer';

// TODO:
// - customer supplier should be accepted after the review form
// - add in `Join Date` and `Added by`

const Network = () => {
    const { midGray } = theme.colors.text;
    const { t: to } = useTranslation('organizationPage');
    const { t } = useTranslation('networkPage');
    const dispatch = useAppDispatch();
    const organization = useAppSelector(selectOrganization);
    const isLoading = useAppSelector(selectOrganizationIsLoading);
    const is_staff = useAppSelector(selectIsStaff);
    const is_admin = useAppSelector(selectIsAdmin);
    const hasAdminLevelAccess = is_admin || is_staff;
    const newOrgButtonRef = useRef<HTMLButtonElement>(null);
    const tableRowRef = useRef<HTMLTableRowElement>(null);
    const toast = useToast();
    const [addRelatedOrgType, setAddRelatedOrgType] =
        useState<organizationRelationshipType | null>(null);
    // Presence of selectedRelatedOrgId means RelatedOrgSidebar
    // is open. Can pass in related org details as props to RelatedOrgSidebar.
    // Use id from props to create Link to "go to org" button in sidebar.
    // Reset selectedRelatedOrgId to null on sidebar close.
    const [selectedRelatedOrg, setSelectedRelatedOrg] =
        useState<RelatedOrganizationType | null>(null);

    const suppliersList: RelatedOrganizationType[] = useMemo(
        () => organization?.suppliers ?? [],
        [organization]
    );
    const customersList: RelatedOrganizationType[] = useMemo(
        () => organization?.customers ?? [],
        [organization]
    );
    const auditorsList: RelatedOrganizationType[] = useMemo(() => [], []);

    if (!organization || isLoading) {
        return (
            <Flex width='100%' height='100%' justifyContent='center'>
                <Spinner />
            </Flex>
        );
    }

    /*
    COSA Staff:
        - Add new org for all organization types
        - Respond to customer invite
        - See invite status for all types
        - Open table row drawer for details
        - Interact with button to redirect to related org page
    Org Admin:
        - Add new org for supplier type
        - Respond to customer invite
        - See invite status for supplier types
        - Open supplier table row drawer for details;
    Org User:
        - See listed names for all organization types
    */
    const RelatedOrgListTable = ({
        orgsList,
        canSeeInviteStatus,
    }: {
        orgsList: RelatedOrganizationType[];
        canSeeInviteStatus: boolean;
    }) => (
        <TableContainer width='100%'>
            <Table variant='simple'>
                <Thead>
                    <Tr>
                        <Th textTransform='none'>
                            <Text variant='small'>{t('Name')}</Text>
                        </Th>
                        {canSeeInviteStatus && (
                            <Th textTransform='none'>
                                <Text variant='small'>{t('Status')}</Text>
                            </Th>
                        )}
                    </Tr>
                </Thead>
                <Tbody>
                    {orgsList.map(details => (
                        <Tr
                            key={details.related_org_id}
                            _hover={
                                canSeeInviteStatus
                                    ? { bg: 'gray.100', cursor: 'pointer' }
                                    : {}
                            }
                            ref={tableRowRef}
                            onClick={() =>
                                setSelectedRelatedOrg(
                                    canSeeInviteStatus ? details ?? null : null
                                )
                            }
                        >
                            <Td>{details.related_org_name}</Td>
                            {/*TODO displayed status cell dependent on status type, entity type, and user type*/}
                            {canSeeInviteStatus && <Td>{t(details.status)}</Td>}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    );

    const EmptyTable = () => (
        <Box
            border={lightGrayLine}
            borderRadius='var(--Radius-xs, 2px)'
            width='100%'
            padding={8}
        >
            <Text textAlign='center' variant='small'>
                {t('None')}
            </Text>
        </Box>
    );

    const onFailAddRelated = () => {
        toast({
            position: 'top',
            title: t('failure'),
            // must use a hardcoded message rather than `error`
            // so that it can be translated
            description: t('addRelatedFailureMessage'),
            status: 'error',
            duration: 3000,
            isClosable: true,
        });
    };

    const onSucceedAddRelated = () => {
        setAddRelatedOrgType(null);
    };

    const addNewOrg = (formData: NewOrganizationParamsType) => {
        dispatch(
            addRelatedOrganization(
                formData,
                organization.id,
                onFailAddRelated,
                onSucceedAddRelated
            )
        );
    };

    return (
        <VStack
            width='100%'
            height='100%'
            alignItems='flex-start'
            justifyContent='flex-start'
            spacing={10}
        >
            {addRelatedOrgType && (
                <AddOrganizationModal
                    finalRef={newOrgButtonRef}
                    isOpen={!!addRelatedOrgType}
                    title={t(
                        addRelatedOrgType === CUSTOMER
                            ? 'newDownstreamOrg'
                            : 'newUpstreamOrg'
                    )}
                    onCloseCallback={() => setAddRelatedOrgType(null)}
                    onSubmitCallback={addNewOrg}
                    relatedOrgType={addRelatedOrgType}
                />
            )}
            {selectedRelatedOrg && (
                <RelatedOrgSnapshotDrawer
                    isOpen={!!selectedRelatedOrg}
                    relatedOrg={selectedRelatedOrg}
                    onCloseCallback={() => setSelectedRelatedOrg(null)}
                    finalRef={tableRowRef}
                />
            )}
            <Text
                style={{
                    color: midGray,
                    fontSize: '30px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '120%',
                }}
            >
                {to('networkTitle')}
            </Text>
            <VStack style={baseContainerStyle}>
                <HStack width='100%' justifyContent='space-between'>
                    <VStack spacing={1}>
                        <Text variant='headingMd'>{t('customersHeading')}</Text>
                        <Text width='100%' variant='small'>
                            {t('customersSubHeading')}
                        </Text>
                    </VStack>
                    {is_staff && (
                        <Button
                            onClick={() => setAddRelatedOrgType(CUSTOMER)}
                            ref={newOrgButtonRef}
                        >
                            {t('addOrgButton')}
                        </Button>
                    )}
                </HStack>
                {customersList.length ? (
                    <RelatedOrgListTable
                        orgsList={customersList}
                        canSeeInviteStatus={is_staff}
                    />
                ) : (
                    <EmptyTable />
                )}
            </VStack>
            <VStack style={baseContainerStyle}>
                <HStack width='100%' justifyContent='space-between'>
                    <VStack spacing={1}>
                        <Text variant='headingMd'>{t('suppliersHeading')}</Text>
                        <Text width='100%' variant='small'>
                            {t('suppliersSubHeading')}
                        </Text>
                    </VStack>
                    {hasAdminLevelAccess && (
                        <Button
                            onClick={() => setAddRelatedOrgType(SUPPLIER)}
                            ref={newOrgButtonRef}
                        >
                            {t('addOrgButton')}
                        </Button>
                    )}
                </HStack>
                {suppliersList.length ? (
                    <RelatedOrgListTable
                        orgsList={suppliersList}
                        canSeeInviteStatus={hasAdminLevelAccess}
                    />
                ) : (
                    <EmptyTable />
                )}
            </VStack>
            <VStack style={baseContainerStyle}>
                <HStack width='100%' justifyContent='space-between'>
                    <VStack spacing={1}>
                        <Text variant='headingMd'>{t('auditorsHeading')}</Text>
                        <Text width='100%' variant='small'>
                            {t('auditorsSubHeading')}
                        </Text>
                    </VStack>
                    {hasAdminLevelAccess && (
                        <Button isDisabled>{t('addAuditorButton')}</Button>
                    )}
                </HStack>
                {auditorsList.length ? (
                    <RelatedOrgListTable
                        orgsList={auditorsList}
                        canSeeInviteStatus={false}
                    />
                ) : (
                    <EmptyTable />
                )}
            </VStack>
        </VStack>
    );
};

export default Network;
