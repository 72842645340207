import { combineReducers } from 'redux';

import appReducer from './app';
import authReducer from './auth';
import nextLocationReducer from './nextLocation';
import organizationsReducer from './organizations';
import organizationReducer from './organization';

const rootReducer = combineReducers({
    auth: authReducer,
    nextLocation: nextLocationReducer,
    app: appReducer,
    organizations: organizationsReducer,
    organization: organizationReducer,
});

export default rootReducer;
