import { useEffect, useState } from 'react';
import { Center, Spinner } from '@chakra-ui/react';

const DelayedSpinner = () => {
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        setTimeout(() => setShowSpinner(true), 1000);
    }, []);

    return showSpinner ? (
        <Center>
            <Spinner />
        </Center>
    ) : null;
};

export default DelayedSpinner;
