export const URLs = {
    makeLoginURL: () => '/auth/login/',
    makeBrowserURL: (path: string) =>
        `${window.location.protocol}//${window.location.host}/${path}`,
    makeOrganizationsURL: () => '/organizations/',
    makeOrganizationURL: (id: string) => `/organizations/${id}/`,
    makeUserOrganizationURL: () => `/organization/`,
    makeNewOrganizationURL: () => `/organization/register/`,
    makeDisableOrganizationURL: (id: string) => `/organization/${id}/disable/`,
    makeNewRelatedOrganizationURL: (id: string) =>
        `organization/${id}/related/`,
    makeUpdateUserURL: (id: string) => `/user/${id}/`,
    makeUpdateOrganizationURL: (id: string) => `/organization/${id}/`,
};

export const Paths = {
    login: '/',
    landing: '/landing/',
    organizations: '/organizations/',
    organization: '/organizations/:id/',
    orgNetwork: '/organizations/:id/network/',
    orgUsers: '/organizations/:id/users/',
    orgData: '/organizations/:id/data/',
    users: '/users/',
    profile: '/profile/',
    permissions: '/permissions/',
    privacy: '/privacy/',
    djangoAdmin: '/admin/',
};

export const organizationTypeOptions = ['CUSTOMER_SUPPLIER', 'AUDITOR'];
export const clientStatusOptions = [
    'ACTIVE_CLIENT',
    'INACTIVE_CLIENT',
    'NOT_A_CLIENT',
];

// Organization types
export const CUSTOMER_SUPPLIER = 'CUSTOMER_SUPPLIER';
export const AUDITOR = 'AUDITOR';

export const CUSTOMER = 'CUSTOMER';
export const SUPPLIER = 'SUPPLIER';

// Client status
export const ACTIVE_CLIENT = 'ACTIVE_CLIENT';
export const INACTIVE_CLIENT = 'INACTIVE_CLIENT';
export const NOT_A_CLIENT = 'NOT_A_CLIENT';

// Tabs
export const HOME = 'HOME';
export const DASHBOARDS = 'DASHBOARDS';
export const YOUR_DATA = 'YOUR_DATA';
export const SHARED_DATA = 'SHARED_DATA';
export const NETWORK = 'NETWORK';
export const PROFILE = 'PROFILE';
export const USERS = 'USERS';

// Parent tabs
export const SETTINGS = 'SETTINGS';
export const DATA = 'DATA';

// Membership roles
export const ADMIN = 'ADMIN';
export const READ_ONLY = 'READ_ONLY';
export const USER = 'USER';

// CustomerSupplier Status roles
export const PENDING = 'PENDING';
export const ACCEPTED = 'ACCEPTED';
export const REJECTED = 'REJECTED';
