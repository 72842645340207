import { useRouteError, isRouteErrorResponse } from 'react-router-dom';
import { Container, Box, Heading, Text } from '@chakra-ui/react';

export default function ErrorPage({ pageError }: { pageError?: string }) {
    const error: unknown = useRouteError();
    console.error(error || pageError);

    return (
        <Container maxWidth='650px'>
            <Box mt='50px'>
                <Heading>Oops!</Heading>
                <Text>Sorry, an unexpected error has occurred.</Text>
            </Box>
            <Box mt='50px'>
                <Text>
                    {/* 
                    Here is the shape of the error response from react-router-dom
                    https://github.com/remix-run/react-router/blob/8e09bd601bd7f3066ff598ddd8e23b9b7cc462b3/packages/router/utils.ts#L1242
                    running `isRouteErrorResponse` ensures that the statusText property is available
                    */}
                    <i>{isRouteErrorResponse(error) && error.statusText}</i>
                    <i>{pageError}</i>
                </Text>
            </Box>
        </Container>
    );
}
