import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsGlobe2 } from 'react-icons/bs';
import { useEffectOnce } from 'react-use';

import { Button, HStack, Icon, Text } from '@chakra-ui/react';

import { selectLanguage, setLanguage, toggleLanguage } from '../reducers/app';
import { selectPreferredLanguage } from '../reducers/auth';
import theme from '../theme';
import { useAppDispatch, useAppSelector } from '../util/hooks';

const { midGray } = theme.colors.text;
const { lightGray } = theme.colors.brand;

const LanguageButton = () => {
    const dispatch = useAppDispatch();
    const { i18n } = useTranslation();
    const preferredLanguage = useAppSelector(selectPreferredLanguage);
    const language = useAppSelector(selectLanguage);

    useEffectOnce(() => {
        if (preferredLanguage) {
            dispatch(setLanguage(preferredLanguage));
        }
    });

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language, i18n]);

    return (
        <Button
            variant='minimal'
            style={{
                color: midGray,
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '150%',
                letterSpacing: '0.64px',
                border: '1px solid',
                borderColor: lightGray,
                padding: '4px',
                height: 'fit-content',
            }}
            onClick={() => dispatch(toggleLanguage())}
        >
            <HStack display='flex' alignItems='center' gap='4px'>
                <Icon as={BsGlobe2} />
                <Text>{language}</Text>
            </HStack>
        </Button>
    );
};

export default LanguageButton;
