import { Flex, Spinner } from '@chakra-ui/react';
import { useAppSelector } from '../util/hooks';
import { selectOrganization } from '../reducers/organization';
import { useNavigate } from 'react-router';
import { Paths } from '../util/constants';

const LandingPage = () => {
    const navigate = useNavigate();
    const organization = useAppSelector(selectOrganization);

    if (organization) {
        navigate(`${Paths.organizations}${organization.id}`);
    }

    return (
        <Flex
            width='100%'
            height='100%'
            alignItems='center'
            justifyContent='center'
        >
            <Spinner />
        </Flex>
    );
};

export default LandingPage;
