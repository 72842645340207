import { Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

const OrgUsersPage = () => {
    const { id } = useParams();

    return (
        <Flex
            width='100%'
            height='100%'
            alignItems='center'
            justifyContent='center'
        >
            Organization {id} Users Page
        </Flex>
    );
};

export default OrgUsersPage;
