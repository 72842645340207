import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCloseFill } from 'react-icons/ri';

import {
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    HStack,
    Input,
    Select,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react';

import { selectIsAdmin, selectOrganization } from '../reducers/organization';
import theme from '../theme';
import { languageType, UserType, UserUpdateType } from '../types/reducers/auth';
import { URLs } from '../util/constants';
import { useAppDispatch, useAppSelector } from '../util/hooks';
import { updateUser } from '../api';
import { setUserInfo } from '../reducers/auth';
import { inputStyles, selectStyle } from '../util/styles';
import { setIsProfileOpen } from '../reducers/app';

const { lightGray } = theme.colors.brand;

const inputRowStyles = {
    alignItems: 'flex-start',
    gap: '4px',
    width: '100%',
};

const ProfileDrawer = ({
    user,
    isProfileOpen,
}: {
    user: UserType;
    isProfileOpen: boolean;
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('profileDrawer');
    const { t: tc } = useTranslation('common');
    const organization = useAppSelector(selectOrganization);
    const isAdmin = useAppSelector(selectIsAdmin);
    const toast = useToast();

    const {
        display_name,
        email,
        preferred_language,
        is_staff: isStaff,
        id,
    } = user;

    const [language, setLanguage] = useState<languageType>();
    const [name, setName] = useState<string>();

    useEffect(() => {
        setLanguage(preferred_language);
        setName(display_name);
    }, [display_name, preferred_language]);

    return (
        <Drawer
            placement='right'
            size='md'
            variant='profile'
            onClose={() => dispatch(setIsProfileOpen(false))}
            isOpen={isProfileOpen}
        >
            <DrawerOverlay />
            <DrawerContent padding='10px'>
                <DrawerHeader>
                    <HStack
                        width='100%'
                        height='40px'
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Text variant='headingMd'>{t('myProfile')}</Text>
                        <Flex
                            width='34px'
                            height='34px'
                            alignItems='center'
                            justifyContent='center'
                            onClick={() => dispatch(setIsProfileOpen(false))}
                            borderRadius='4px'
                            _hover={{
                                background: lightGray,
                            }}
                        >
                            <RiCloseFill height='24px' />
                        </Flex>
                    </HStack>
                </DrawerHeader>
                <DrawerBody>
                    <VStack gap='24px' width='100%'>
                        {!isStaff && organization && (
                            <HStack width='100%' gap='16px'>
                                <VStack
                                    width='50%'
                                    alignItems='flex-start'
                                    gap='0px'
                                >
                                    <Text
                                        variant='small'
                                        textTransform='capitalize'
                                    >
                                        {tc('organization')}
                                    </Text>
                                    <Text variant='md' fontWeight='400'>
                                        {organization.name}
                                    </Text>
                                </VStack>
                                <VStack
                                    width='50%'
                                    alignItems='flex-start'
                                    gap='0px'
                                >
                                    <Text
                                        variant='small'
                                        textTransform='capitalize'
                                    >
                                        {tc('role')}
                                    </Text>
                                    <Text variant='md' fontWeight='400'>
                                        {isAdmin ? t('orgAdmin') : t('orgUser')}
                                    </Text>
                                </VStack>
                            </HStack>
                        )}

                        <VStack {...inputRowStyles}>
                            <Text
                                variant={'small'}
                                textTransform={'capitalize'}
                            >
                                {tc('name')}
                            </Text>
                            <Input
                                {...inputStyles}
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </VStack>
                        <VStack {...inputRowStyles}>
                            <Text
                                variant={'small'}
                                textTransform={'capitalize'}
                            >
                                {tc('email')}
                            </Text>
                            <Input
                                disabled={true}
                                {...inputStyles}
                                value={email}
                            />
                        </VStack>
                        <VStack {...inputRowStyles}>
                            <Text
                                variant={'small'}
                                textTransform={'capitalize'}
                            >
                                {tc('preferredLanguage')}
                            </Text>
                            <Select
                                style={selectStyle}
                                value={language ? language : undefined}
                                placeholder={
                                    !language ? t('selectLanguage') : undefined
                                }
                                onChange={e =>
                                    setLanguage(
                                        e.target.value as typeof language
                                    )
                                }
                            >
                                <option value='en'>{tc('en')}</option>
                                <option value='es'>{tc('es')}</option>
                            </Select>
                        </VStack>
                        <HStack
                            width='100%'
                            alignItems='center'
                            justifyContent='flex-end'
                            pt='10px'
                        >
                            <Button
                                height='32px'
                                variant='primary'
                                onClick={() => {
                                    setName(display_name);
                                    setLanguage(preferred_language);
                                }}
                            >
                                {t('discard')}
                            </Button>
                            <Button
                                height='32px'
                                variant='submit'
                                onClick={async () => {
                                    try {
                                        const payload: UserUpdateType = {
                                            preferred_language: language,
                                            display_name: name,
                                            is_active: undefined,
                                        };
                                        const data = await updateUser(
                                            id,
                                            payload
                                        );

                                        dispatch(setUserInfo(data));
                                        dispatch(setIsProfileOpen(false));

                                        toast({
                                            position: 'top',
                                            title: t('success'),
                                            description: t('successMessage'),
                                            status: 'success',
                                            duration: 3000,
                                            isClosable: true,
                                        });
                                    } catch (error) {
                                        toast({
                                            position: 'top',
                                            title: t('failure'),
                                            description: t('failureMessage'),
                                            status: 'error',
                                            duration: 3000,
                                            isClosable: true,
                                        });
                                    }
                                }}
                            >
                                {t('save')}
                            </Button>
                        </HStack>
                    </VStack>
                </DrawerBody>
                <DrawerFooter pt='16px'>
                    <Button
                        width='100%'
                        height='40px'
                        onClick={e => {
                            e.preventDefault();
                            return window.location.assign(
                                URLs.makeBrowserURL('api/auth0/logout/')
                            );
                        }}
                    >
                        <Text variant={'md'}>{t('signOut')}</Text>
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};

export default ProfileDrawer;
