import { AxiosError } from 'axios';
import { isString, toNumber, trimEnd, isNaN, inRange, isEmpty } from 'lodash';

export const getErrorMessage = (e: unknown, message: string): string => {
    return e instanceof AxiosError && e.response?.data?.detail !== undefined
        ? e.response?.data?.detail
        : message;
};

export const isValidCoordinate = (
    coordString: string | undefined,
    latitudeOrLongitude: 'latitude' | 'longitude'
): boolean => {
    if (!isString(coordString)) return false;
    if (isEmpty(coordString)) return false;

    const cleanLat = trimEnd(coordString).replace(/°$/, '');
    const lat = toNumber(cleanLat);
    if (isNaN(lat)) return false;
    if (latitudeOrLongitude === 'latitude') {
        return inRange(lat, -90, 91);
    }
    if (latitudeOrLongitude === 'longitude') {
        return inRange(lat, -180, 181);
    }

    return false;
};

export const isValidLatitude = (latStr: string | undefined) => {
    return isValidCoordinate(latStr, 'latitude');
};

export const isValidLongitude = (longStr: string | undefined) => {
    return isValidCoordinate(longStr, 'longitude');
};
