import { Text, VStack } from '@chakra-ui/react';

import theme from '../theme';

const { midGray, orange } = theme.colors.text;

const PageHeading = ({
    title,
    subheading,
}: {
    title: string;
    subheading?: string;
}) => (
    <VStack
        width='100%'
        display='flex'
        alignItems='flex-start'
        alignSelf='stretch'
    >
        <Text
            style={{
                color: midGray,
                fontSize: '30px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '120%',
            }}
        >
            {title}
        </Text>
        {subheading && (
            <Text
                style={{
                    color: orange,
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '150%',
                }}
                variant='table-subtitle'
            >
                {subheading}
            </Text>
        )}
    </VStack>
);

export default PageHeading;
