import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';

import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Select,
    Spacer,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react';

import { updateOrganization } from '../reducers/organization';
import theme from '../theme';
import { OrganizationType } from '../types/reducers/organizations';
import { COUNTRY_CHOICES } from '../util/countries';
import { useAppDispatch } from '../util/hooks';
import {
    inputStyles,
    modalWidth,
    radius,
    selectStyle,
    smallTextStyle,
    spacing10,
    spacing2,
    spacing4,
    spacing6,
    spacing8,
} from '../util/styles';
import { isEmpty } from 'lodash';

const { darkGreen } = theme.colors.brand;
const { midGray } = theme.colors.text;

type OrganizationState = {
    name?: string;
    address?: string;
    country?: string;
    latitude?: string;
    longitude?: string;
};

type Action =
    | { type: 'SET_ORG_NAME'; payload: string }
    | { type: 'SET_ADDRESS'; payload: string | undefined }
    | { type: 'SET_COUNTRY'; payload: string | undefined }
    | { type: 'SET_LATITUDE'; payload: string | undefined }
    | { type: 'SET_LONGITUDE'; payload: string | undefined };

const reducer = (
    state: OrganizationState,
    action: Action
): OrganizationState => {
    switch (action.type) {
        case 'SET_ORG_NAME':
            return { ...state, name: action.payload };
        case 'SET_ADDRESS':
            return { ...state, address: action.payload };
        case 'SET_COUNTRY':
            return { ...state, country: action.payload };
        case 'SET_LATITUDE':
            return { ...state, latitude: action.payload };
        case 'SET_LONGITUDE':
            return { ...state, longitude: action.payload };
        default:
            return state;
    }
};

const ReviewForm = ({ organization }: { organization: OrganizationType }) => {
    const { t } = useTranslation('organizationPage');
    const dispatchRedux = useAppDispatch();
    const toast = useToast();

    useEffectOnce(() => {
        // Only org name and country should be filled when this form is presented
        // but to be safe we can populate all of them with the saved values for
        // the org
        dispatch({ type: 'SET_ORG_NAME', payload: organization.name });
        dispatch({ type: 'SET_COUNTRY', payload: organization.country });
        dispatch({ type: 'SET_ADDRESS', payload: organization.address });
        dispatch({ type: 'SET_LONGITUDE', payload: organization.longitude });
        dispatch({ type: 'SET_LATITUDE', payload: organization.latitude });
    });

    const [state, dispatch] = useReducer(reducer, {} as OrganizationState);

    const nameError = isEmpty(state.name);
    const addressError = isEmpty(state.address);

    const formIsInvalid = nameError || addressError;

    const saveClickHandler = () => {
        const data = { ...state, ...{ reviewed: true } };

        const failureCallback = () => {
            toast({
                position: 'top',
                title: t('failure'),
                // must use a hardcoded message rather than `error`
                // so that it can be translated
                description: t('updateFailureMessage'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        };

        dispatchRedux(
            updateOrganization(organization.id, data, failureCallback)
        );
    };

    return (
        <Flex
            width='100%'
            paddingX={spacing8}
            paddingY={spacing10}
            justifyContent='center'
        >
            <VStack
                width={modalWidth}
                backgroundColor='white'
                borderRadius={radius.s}
                gap={spacing6}
                padding={spacing6}
                alignItems='flex-start'
            >
                <VStack gap={spacing4}>
                    <Text variant='headingMd' color={darkGreen}>
                        {t('welcome')}
                    </Text>
                    <Text variant='md' color={midGray} fontWeight={400}>
                        {t('subheading')}
                    </Text>
                </VStack>
                <Text variant='headingMd'>{t('generalInfo')}</Text>
                <FormControl
                    style={smallTextStyle}
                    isRequired
                    gap={spacing2}
                    isInvalid={nameError}
                >
                    <FormLabel>{t('organizationName')}</FormLabel>
                    <Input
                        {...inputStyles}
                        value={state.name}
                        onChange={e =>
                            dispatch({
                                type: 'SET_ORG_NAME',
                                payload: e.target.value,
                            })
                        }
                        type='text'
                    />
                    {nameError && (
                        <FormErrorMessage>
                            Enter a valid organization name
                        </FormErrorMessage>
                    )}
                </FormControl>
                <Spacer />
                <VStack
                    gap={spacing6}
                    justifyContent='flex-start'
                    alignItems='flex-start'
                >
                    <Text variant='headingMd'>{t('address')}</Text>
                    <FormControl
                        style={smallTextStyle}
                        gap={spacing2}
                        isRequired
                    >
                        <FormLabel>{t('country')}</FormLabel>
                        <Select
                            {...selectStyle}
                            value={state.country}
                            onChange={e =>
                                dispatch({
                                    type: 'SET_COUNTRY',
                                    payload: e.target.value,
                                })
                            }
                        >
                            {Object.entries(COUNTRY_CHOICES).map(
                                ([code, label]) => (
                                    <option key={code} value={code}>
                                        {label}
                                    </option>
                                )
                            )}
                        </Select>
                    </FormControl>
                    <FormControl
                        style={smallTextStyle}
                        gap={spacing2}
                        isInvalid={addressError}
                        isRequired
                    >
                        <FormLabel>{t('address')}</FormLabel>
                        <Input
                            {...inputStyles}
                            value={state.address}
                            onChange={e =>
                                dispatch({
                                    type: 'SET_ADDRESS',
                                    payload: e.target.value,
                                })
                            }
                            type='text'
                        />
                        {addressError && (
                            <FormErrorMessage>
                                Enter a valid address
                            </FormErrorMessage>
                        )}
                    </FormControl>
                    <FormControl style={smallTextStyle} gap={spacing2}>
                        <FormLabel>{t('longitude')}</FormLabel>
                        <Input
                            {...inputStyles}
                            value={state.longitude}
                            onChange={e =>
                                dispatch({
                                    type: 'SET_LONGITUDE',
                                    payload: e.target.value,
                                })
                            }
                            type='number'
                        />
                    </FormControl>
                    <FormControl style={smallTextStyle} gap={spacing2}>
                        <FormLabel>{t('latitude')}</FormLabel>
                        <Input
                            {...inputStyles}
                            value={state.latitude}
                            onChange={e =>
                                dispatch({
                                    type: 'SET_LATITUDE',
                                    payload: e.target.value,
                                })
                            }
                            type='number'
                        />
                    </FormControl>
                </VStack>
                <Button
                    variant='submit'
                    display='flex'
                    alignContent='center'
                    justifyContent='center'
                    onClick={saveClickHandler}
                    alignSelf='flex-end'
                    isDisabled={formIsInvalid}
                >
                    <Text>{t('save')}</Text>
                </Button>
            </VStack>
        </Flex>
    );
};

export default ReviewForm;
