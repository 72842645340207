import { Flex, HStack, Image } from '@chakra-ui/react';

import Auth0Login from './Auth0Login';

const Login = () => {
    return (
        <HStack width='100%' height='100vh'>
            <Flex height='100%' width='50%'>
                <Auth0Login />
            </Flex>
            <Flex
                height='100%'
                width='50%'
                alignItems='center'
                justifyContent='center'
                overflow='hidden'
            >
                <Image
                    src='/img/trees.png'
                    alt='login background'
                    width='auto'
                    height='100%'
                    objectFit='cover'
                />
            </Flex>
        </HStack>
    );
};

export default Login;
