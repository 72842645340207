import {
    AnyAction,
    createSlice,
    PayloadAction,
    ThunkDispatch,
} from '@reduxjs/toolkit';

import * as api from '../api';
import { RootState } from '../store';
import {
    clientStatusType,
    NewOrganizationParamsType,
    OrganizationResponseType,
    OrganizationState,
    OrganizationType,
    organizationTypeType,
    OrganizationUpdateType,
} from '../types/reducers/organizations';
import { ThunkType } from '../types/types';
import { getErrorMessage } from '../util/utils';

const initialState: OrganizationState = {
    organization: null,
    loading: false,
    saving: false,
    error: null,
    isAdmin: null,
    users: null,
};

export const organizationSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        startFetchOrganization: (state: OrganizationState) => {
            state.organization = null;
            state.loading = true;
            state.error = null;
        },
        completeFetchOrganization: (
            state: OrganizationState,
            { payload }: PayloadAction<OrganizationResponseType>
        ) => {
            state.organization = payload.organization;
            state.loading = false;
            state.error = null;
            state.isAdmin = payload.is_admin;
            state.users = payload.users;
        },
        failFetchOrganization: (
            state: OrganizationState,
            { payload }: PayloadAction<string>
        ) => {
            state.organization = null;
            state.loading = false;
            state.error = payload;
        },
        startUpdateOrganization: (state: OrganizationState) => {
            state.saving = true;
            state.error = null;
        },
        completeUpdateOrganization: (
            state: OrganizationState,
            { payload }: PayloadAction<OrganizationType>
        ) => {
            state.organization = payload;
            state.saving = false;
        },
        failUpdateOrganization: (
            state: OrganizationState,
            { payload }: PayloadAction<string>
        ) => {
            state.saving = false;
            state.error = payload;
        },
        setOrganization: (
            state: OrganizationState,
            { payload }: PayloadAction<OrganizationType>
        ) => {
            state.organization = payload;
        },
        setOrganizationType: (
            state: OrganizationState,
            { payload }: PayloadAction<organizationTypeType>
        ) => {
            if (state.organization) {
                state.organization.organization_type = payload;
            }
        },
        setOrganizationClientStatus: (
            state: OrganizationState,
            { payload }: PayloadAction<clientStatusType>
        ) => {
            if (state.organization) {
                state.organization.client_status = payload;
            }
        },
        startAddNewRelatedOrganization: (state: OrganizationState) => {
            state.loading = true;
            state.error = null;
        },
        failAddNewRelatedOrganization: (
            state: OrganizationState,
            { payload }: PayloadAction<string>
        ) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const {
    startFetchOrganization,
    completeFetchOrganization,
    failFetchOrganization,
    startUpdateOrganization,
    completeUpdateOrganization,
    failUpdateOrganization,
    setOrganization,
    setOrganizationType,
    setOrganizationClientStatus,
    startAddNewRelatedOrganization,
    failAddNewRelatedOrganization,
} = organizationSlice.actions;

export const fetchOrganization =
    (id?: string): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startFetchOrganization());
        try {
            if (id) {
                const data = await api.getOrganization(id);
                dispatch(completeFetchOrganization(data));
            } else {
                const data = await api.getUserOrganization();
                dispatch(completeFetchOrganization(data));
            }
        } catch (e: unknown) {
            const message: string = getErrorMessage(
                e,
                'Failed to fetch organization.'
            );

            dispatch(failFetchOrganization(message));
        }
    };

export const updateOrganization =
    (
        id: string,
        organization: OrganizationUpdateType,
        onFailure?: () => void,
        onSuccess?: () => void
    ): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startUpdateOrganization());
        try {
            const data = await api.putOrganization(id, organization);
            dispatch(completeUpdateOrganization(data));
            if (onSuccess) {
                onSuccess();
            }
        } catch (e: unknown) {
            const message: string = getErrorMessage(
                e,
                'Failed to update organization.'
            );
            dispatch(failUpdateOrganization(message));
            if (onFailure) {
                onFailure();
            }
        }
    };

export const addRelatedOrganization =
    (
        params: NewOrganizationParamsType,
        id: string,
        onFailure?: () => void,
        onSuccess?: () => void
    ): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startAddNewRelatedOrganization());
        try {
            await api.postNewRelatedOrganization(params, id);
            // Refetch organization for new related orgs list
            // Instead of patching existing state
            dispatch(fetchOrganization(id));
            if (onSuccess) {
                onSuccess();
            }
        } catch (e: unknown) {
            const message: string = getErrorMessage(
                e,
                'Failed to add new organization.'
            );

            dispatch(failAddNewRelatedOrganization(message));
            if (onFailure) {
                onFailure();
            }
        }
    };

export const selectOrganization = (state: RootState) =>
    state.organization.organization;
export const selectIsAdmin = (state: RootState) => state.organization.isAdmin;
export const selectOrganizationMembers = (state: RootState) =>
    state.organization.users;

export const selectOrganizationIsLoading = (state: RootState) =>
    state.organization.loading;

export default organizationSlice.reducer;
